/* Card container style */
.table-card-container {
    background-color: #ffffff; /* White background for the card */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    padding: 20px; /* Padding around the table */
    margin: 20px 0; /* Margin around the card */
    border-radius: 4px; 
  }
  
  /* Table header style */
  .table-header-custom {
    background-color: #f8f9fa; /* Light grey background for header */
    color: #000000; /* Black text color for header */
  }
  
  /* Table input fields style */
  .table-input-custom {
    background-color: #ffffff !important; /* White background for input fields */
    color: #000000 !important; /* Black text color for input fields */
    border: 1px solid #ced4da !important; /* Light grey border for input fields */
  }
  
  /* Table border style */
  .table-border-custom {
    border: 1px solid #ced4da !important; /* Light grey border for the table and cells */
  }
  
  /* Table row style */
  .table-row-custom {
    border-bottom: 1px solid #ced4da !important; /* Light grey border for rows */
  }
  
  .table-bg-custom {
    background-color: #ffffff !important; /* White background for table rows */
  }


  /* Preloader transition */
.app-container {
  transition: opacity 1.5s ease-in-out; 
  opacity: 0; 
}

.app-container.opacity-100 {
  opacity: 1; 
}

@keyframes fade-in-scale {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes slide-in {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.opacity-100 {
  opacity: 1;
}

.opacity-0 {
  opacity: 0;
}
